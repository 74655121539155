(function(){
  const body = document.getElementsByTagName('body')[0];
  const route = body.dataset.route;

  if (route === 'admin_event_log_edit') {
    let eventLogContexts = document.getElementsByClassName('event_log_context');

    for(let i = 0; i<eventLogContexts.length; i++){

      eventLogContexts[i].textContent = prettier.format(eventLogContexts[i].textContent, {
        parser: 'json',
        plugins: prettierPlugins
      });

      let jsonEditor = CodeMirror.fromTextArea(eventLogContexts[i], {
        readOnly: true,
        mode: { name: 'javascript', json: true },
        lineNumbers: true,
        lineWrapping: true,
        extraKeys: { 'Ctrl-Q': function (cm) { cm.foldCode(cm.getCursor()); } },
        foldGutter: true,
        gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter'],
        foldOptions: {
          widget: (from, to) => {
            let count;
            // Get open / close token
            var startToken = '{'; var endToken = '}';
            var prevLine = jsonEditor.getLine(from.line);
            if (prevLine.lastIndexOf('[') > prevLine.lastIndexOf('{')) {
              startToken = '[';
              endToken = ']';
            }

            // Get json content
            var internal = jsonEditor.getRange(from, to);
            var toParse = startToken + internal + endToken;

            // Get key count
            try {
              var parsed = JSON.parse(toParse);
              count = Object.keys(parsed).length;
            } catch (e) { }

            return count ? `\u21A4${count}\u21A6` : '\u2194';
          }
        }
      });

      document.getElementById('copy-' + eventLogContexts[i].id.split('context-')[1]).addEventListener('click', function () {
        const el = document.createElement('textarea');
        el.value = jsonEditor.getValue();
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
      });
    }

    $(".collapse").on('shown.bs.collapse', function () {
      $('.CodeMirror').each(function(i, el){
        el.CodeMirror.refresh();
      });
    })
  }
})();
